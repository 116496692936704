export default {
    init: () => {
        /*
        Functions
        ========================================================================== */

        //== Modal center
        function centerModal() {
            $(this).css('display', 'block');
            var $dialog = $(this).find(".modal-dialog"),
                offset = ($(window).height() - $dialog.height()) / 2,
                bottomMargin = parseInt($dialog.css('marginBottom'), 10);
            if (offset < bottomMargin) offset = bottomMargin;
            $dialog.css("margin-top", offset);
        }
        $(document).on('show.bs.modal', '.modal', centerModal);

        //== Accordion panel arrows toogle
        function togglePanel(e) {
            $(e.target).prev('.panel-heading').toggleClass('active');
        }

        //== https://browser-update.org/
        var $buoop = {
            vs: {i: 8, f: -8, o: -8, s: 6, c: -8},
            l: "fr",
            api: 4,
            reminder: 0,
            text: "Votre navigateur {brow_name} est trop ancien pour utliser ce site et ses services : <a{up_but}>Mettre à jour</a>"
        };
        function $buo_f() {
            var e = document.createElement("script");
            e.src = "//browser-update.org/update.min.js";
            document.body.appendChild(e);
        }
        try {
            document.addEventListener("DOMContentLoaded", $buo_f, false)
        }
        catch (e) {
            window.attachEvent("onload", $buo_f)
        }

        /*
        Ready
        ========================================================================== */

        jQuery(document).ready(function ($) {

            var body = $('body');
            //== Print
            $('.pt-content-print').on('click', function (e) {
                e.preventDefault();
                window.print();
            });

            //== Nav panel
            var navPanelButton = $('.nav-panel-button');
            if (navPanelButton.length) {
                navPanelButton.jPushMenu();
            }

            $('.jPushMenuBtn').on('click', function (e) {
                $('body').toggleClass('navigation-panel-open');
            });
            $(document).on('click', function (e) {
                if (body.hasClass('navigation-panel-open')) {
                    body.removeClass('navigation-panel-open');
                }
            });

            //== Form elements
            $('.form-group-infos-trigger').on('click', function (e) {
                e.preventDefault();
                $(this).closest('div').toggleClass('active').find('.form-group-infos').toggle();
            });
            $('.alert-boxes-triggers').on('mouseover', 'a', function (e) {
                let $type = $(this).attr('class');
                $('.alert-box').hide();
                $(this).closest('.panel-form-row').find('.alert-box.' + $type + '-type').toggle();
            });
            $('.panel-heading').on('mouseleave', function (e) {
                $('.alert-box').hide();
            });
            $('.alert-boxes-triggers').on('click', 'a', function (e) {
                e.preventDefault();
            });
            $('.alert-box').on('click', function (e) {
                e.preventDefault();
                $('.alert-box').hide();
            });

            $('.alert-form-row .alert-boxes-triggers').on('mouseover', 'a', function (e) {
                let $type = $(this).attr('class');
                $('.alert-box').hide();
                $(this).closest('.alert-form-row').find('.alert-box.' + $type + '-type').toggle();
            });
            $('.alert-form-row .alert-boxes-triggers').on('mouseleave', function (e) {
                $('.alert-box').hide();
            });

            $('.toggle-password').on('click', '.checkbox-theme input', function (e) {

                var $thisContainer = $(this).closest('.toggle-password');
                var $thisInput = $thisContainer.find('.control-password');

                if ($thisInput.attr("type") == 'password') {
                    $thisInput.prop("type", "text");
                }
                else {
                    $thisInput.prop("type", "password");
                }
            });

            //== Accordion panel arrows toogle
            $('.accordion').on('hidden.bs.collapse', togglePanel);
            $('.accordion').on('shown.bs.collapse', togglePanel);
        });
    }
}