// Import utils
import Router from '@shared/utils/router'

/*
|
| Importing Libs
|------------------
*/

/*
|
| Importing Pages
|-----------------------
*/
// import main from '@pages/main'

/*
|
| Importing Legacy
|-----------------------
*/
import legacyApp from '@legacy/app'


new Router([
    {
        file: legacyApp
    },
    // {
    //     file: main
    // },

]).load()