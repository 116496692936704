export default class Router {
	constructor(routes) {
		this.routes = routes
	}

	load() {
        this.routes.forEach(route => {
            if(typeof route.resolve !== 'undefined') {
				document.querySelectorAll(route.resolve).length && this.runInitialization(route)
			} else {
				this.runInitialization(route)
			}
        })
	}

	runInitialization(route) {
		route.file.init.apply(null, route.dependencies)
	}
}